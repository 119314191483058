import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Microsoft Powerpoint"
    img_name="powerpoint_logo.png"
    link="https://www.microsoft.com/microsoft-365/powerpoint"
    description="Microsoft Powerpoint is still surprisingly the best software solution for creating presentations on Windows for those that don't want to work using a web-based tool."
    twitterHandleTool="@powerpoint"
  />
)

export default Tool;
